$(".accordion_tab").click(function() {
  $(".accordion_tab").each(function() {
    $(this)
      .parent()
      .removeClass("active");
    $(this).removeClass("active");
  });
  $(this)
    .parent()
    .addClass("active");
  $(this).addClass("active");
});
