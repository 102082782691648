$(".introduction").mousemove(function(e) {
  parallaxIt(e, ".after-text", 20);
  parallaxIt(e, "h1", -50);
});

function parallaxIt(e, target, movement) {
  var $this = $(".introduction");
  var relX = e.pageX - $this.offset().left;
  var relY = e.pageY - $this.offset().top;

  TweenMax.to(target, 1, {
    x: ((relX - $this.width() / 2) / $this.width()) * movement,
    y: ((relY - $this.height() / 2) / $this.height()) * movement
  });
}
