import "./import/modules.js";
import { TweenMax, Expo } from "gsap";

$(document).ready(function() {
  var imgLoad = imagesLoaded(".content");

  var progressBar = $(".c-preloader__progress"),
    count = $(".c-preloader__count"),
    images = $("img").length,
    loadedCount = 0,
    loadingProgress = 0,
    tlProgress = new TimelineMax();

  imgLoad.on("progress", function(instance, image) {
    loadProgress();
  });

  function loadProgress(imgLoad, image) {
    loadedCount++;

    loadingProgress = loadedCount / images;
    console.log(loadingProgress);

    TweenMax.to(tlProgress, 1, { progress: loadingProgress });
  }

  var tlProgress = new TimelineMax({
    onUpdate: countPercent,
    onComplete: loadComplete
  });

  tlProgress.to(progressBar, 1, { width: "100%" });

  function countPercent() {
    var newPercent = (tlProgress.progress() * 100).toFixed();
    count.text(newPercent + "%");
  }

  function loadComplete() {
    var tlEnd = new TimelineMax();
    tlEnd
      .to(count, 0.5, { autoAlpha: 0 })
      .to(".c-preloader", 0.5, { scaleX: 0, transformOrigin: "center right" });
  }
});

var scroll = new SmoothScroll('a[href^="#"]', {
  speed: 300
});

var html = document.documentElement;

if (sessionStorage.fontsLoaded) {
  html.classList.add("fonts-loaded");
} else {
  var script = document.createElement("script");
  script.async = true;

  script.onload = function() {
    var regular = new FontFaceObserver("Travel Soulmates Signatur");
    var bold = new FontFaceObserver("Travel Soulmates Signatur", {
      weight: "normal"
    });

    Promise.all([regular.load(), bold.load()]).then(function() {
      html.classList.add("fonts-loaded");
      sessionStorage.fontsLoaded = true;
    });
  };
  document.head.appendChild(script);
}
if ($(window).width() >= 1024) {
  $(function() {
    // wait for document ready
    // init
    var controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: "onLeave",
        duration: "100%"
      }
    });

    // get all slides
    var slides = document.querySelectorAll("section.panel");

    // create scene for every slide
    for (var i = 0; i < slides.length; i++) {
      new ScrollMagic.Scene({
        triggerElement: slides[i]
      })
        .setPin(slides[i], { pushFollowers: false })
        //.addIndicators() // add indicators (requires plugin)
        .addTo(controller);
    }
  });
}
