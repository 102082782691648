var controller = new ScrollMagic.Controller();

$(".step-tween").each(function() {
  var tween = TweenMax.from(this, 1, {
    autoAlpha: 0,
    y: "+=30",
    ease: Power2.easeOut
  });
  // build a scene
  var ourScene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.3,
    offset: 100
  })
    .setTween(tween)
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    //   colorEnd: "pink"
    // })
    .addTo(controller);
});

var stagger8 = TweenMax.staggerFrom(
  ".first-step-bottom-text",
  1,
  {
    y: 40,
    autoAlpha: 0,
    delay: 0,
    ease: Power2.easeOut
  },
  0.6
);
var scene8 = new ScrollMagic.Scene({
  triggerElement: ".first-step",
  triggerHook: 0.3
})
  .setTween(stagger8)
  // .addIndicators({
  //   name: "fade scene3",
  //   colorTrigger: "black",
  //   colorStart: "#75C695",
  //   colorEnd: "pink"
  // })
  .addTo(controller);

// $(window).resize(function() {
//   if ($(window).width() < 1440) {
//     function getMobileSlide001Tl() {
//       var mobileSlide001Tl = new TimelineMax();

//       mobileSlide001Tl
//         .fromTo(
//           ".panel-img1",
//           1,
//           { autoAlpha: 1 },
//           { y: "0", autoAlpha: 0, ease: Linear.easeNone }
//         )
//         .add("screen1-in");
//       return mobileSlide001Tl;
//     }

//     function getMobileSlide002Tl() {
//       var mobileSlide002Tl = new TimelineMax();

//       mobileSlide002Tl
//         .fromTo(
//           ".panel-img2",
//           1,
//           { autoAlpha: 1 },
//           { y: "0", autoAlpha: 0, ease: Linear.easeNone }
//         )
//         .add("screen2-in");
//       return mobileSlide002Tl;
//     }

//     function getMobileSlide003Tl() {
//       var mobileSlide003Tl = new TimelineMax();

//       mobileSlide003Tl
//         .fromTo(
//           ".panel-img3",
//           1,
//           { autoAlpha: 0 },
//           { y: "0", autoAlpha: 1, ease: Linear.easeNone }
//         )
//         .add("screen4-in");
//       return mobileSlide003Tl;
//     }

//     var controller = new ScrollMagic.Controller();
//     // Scene 1 - pin our main section
//     var pinScene001 = new ScrollMagic.Scene({
//       triggerElement: ".panel-wrapper",
//       triggerHook: 0,
//       duration: "400%"
//     })
//       .setPin(".panel-wrapper .panel-img-wrapper", {
//         pushFollowers: false
//       })
//       .addIndicators({
//         name: "triggerDown",
//         indent: 820,
//         colorStart: "yellow",
//         colorTrigger: "yellow"
//       })
//       .addTo(controller);

//     var pinScene002 = new ScrollMagic.Scene({
//       triggerElement: ".panel-wrapper",
//       triggerHook: 0,
//       offset: "0",
//       duration: "5%"
//     })
//       .setTween(getMobileSlide001Tl())
//       .addIndicators()
//       .addTo(controller);

//     var pinScene003 = new ScrollMagic.Scene({
//       triggerElement: ".panel-wrapper",
//       triggerHook: 0,
//       offset: 200,
//       duration: "60%"
//     })
//       .setTween(getMobileSlide002Tl())
//       .addIndicators()
//       .addTo(controller);

//     var pinScene004 = new ScrollMagic.Scene({
//       triggerElement: ".panel-wrapper",
//       triggerHook: 0,
//       offset: 1800,
//       duration: "60%"
//     })
//       .setTween(getMobileSlide003Tl())
//       .addIndicators()
//       .addTo(controller);
//   }
// });

if ($(window).width() >= 1026) {
  function getMobileSlide01Tl() {
    var mobileSlide01Tl = new TimelineMax();

    mobileSlide01Tl
      .fromTo(
        ".panel-img1",
        0.8,
        { autoAlpha: 1 },
        { y: "0", autoAlpha: 0, ease: Linear.easeNone }
      )
      .add("screen1-in");
    return mobileSlide01Tl;
  }

  function getMobileSlide02Tl() {
    var mobileSlide02Tl = new TimelineMax();

    mobileSlide02Tl
      .fromTo(
        ".panel-img2",
        0.8,
        { autoAlpha: 1 },
        { y: "0", autoAlpha: 0, ease: Linear.easeNone }
      )
      .add("screen2-in");
    return mobileSlide02Tl;
  }

  function getMobileSlide03Tl() {
    var mobileSlide03Tl = new TimelineMax();

    mobileSlide03Tl
      .fromTo(
        ".panel-img3",
        0.8,
        { autoAlpha: 0 },
        { y: "0", autoAlpha: 1, ease: Linear.easeNone }
      )
      .add("screen4-in");
    return mobileSlide03Tl;
  }

  var controller = new ScrollMagic.Controller();
  // Scene 1 - pin our main section
  var pinScene01 = new ScrollMagic.Scene({
    triggerElement: ".panel-wrapper",
    triggerHook: 0,
    duration: "400%"
  })
    .setPin(".panel-wrapper .panel-img-wrapper", { pushFollowers: false })
    // .addIndicators({
    //   name: "triggerDown",
    //   indent: 520,
    //   colorStart: "yellow",
    //   colorTrigger: "yellow"
    // })
    .addTo(controller);

  var pinScene02 = new ScrollMagic.Scene({
    triggerElement: ".panel-wrapper",
    triggerHook: 0,
    offset: "0",
    duration: "58%"
  })
    .setTween(getMobileSlide01Tl())
    //.addIndicators()
    .addTo(controller);

  var pinScene03 = new ScrollMagic.Scene({
    triggerElement: ".panel-wrapper",
    triggerHook: 0.3,
    offset: 1200,
    duration: "75%"
  })
    .setTween(getMobileSlide02Tl())
    //.addIndicators()
    .addTo(controller);

  var pinScene04 = new ScrollMagic.Scene({
    triggerElement: ".panel-wrapper",
    triggerHook: 0.8,
    offset: 2600,
    duration: "45%"
  })
    .setTween(getMobileSlide03Tl())
    //.addIndicators()
    .addTo(controller);
}
