import "../fontfaceobserver";
import "../slick-animation.min";
import "%modules%/header/header";
import "%modules%/experience/experience";
import "%modules%/philosophy/philosophy";
import "%modules%/whyarewe/whyarewe";
import "%modules%/team/team";
import "%modules%/howwework/howwework";
import "%modules%/instagram/instagram";
import "%modules%/newslater/newslater";
import "%modules%/header-exp/header-exp";
import "%modules%/map/map";
import "%modules%/step/step";
import "%modules%/details/details";
import "%modules%/cost/cost";
import "%modules%/info/info";
import "%modules%/next-tours/next-tours";
import "%modules%/footer/footer";
