import "slick-carousel";

$(".whyarewe-slider").slick({
  dots: false,
  arrows: false,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 5,
  swipeToSlide: true,
  centerMode: false,
  variableWidth: true,
  focusOnSelect: false,
  rows: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 1,
        infinite: true,
        draggable: true,
        swipe: true,
        slidesToShow: 1
      }
    }
  ]
});
$(".left").click(function() {
  $(".whyarewe-slider").slick("slickPrev");
});

$(".right").click(function() {
  $(".whyarewe-slider").slick("slickNext");
});

var xCoordStart,
  yCoordStart,
  xSlideTrigger = 10,
  slickElement = $(".whyarewe-slider");

slickElement.bind("touchstart", function(e) {
  xCoordStart = e.originalEvent.touches[0].clientX;
  yCoordStart = e.originalEvent.touches[0].clientY;
});

slickElement.bind("touchend", function(e) {
  var xCoordEnd = e.originalEvent.changedTouches[0].clientX;
  var yCoordEnd = e.originalEvent.changedTouches[0].clientY;

  var deltaX = Math.abs(xCoordEnd - xCoordStart);
  var deltaY = Math.abs(yCoordEnd - yCoordStart);

  if (deltaX > deltaY) {
    // prevent slide while scrolling vertically
    if (xCoordStart > xCoordEnd + xSlideTrigger) {
      slickElement.slick("slickNext");
    } else if (xCoordStart < xCoordEnd + xSlideTrigger) {
      slickElement.slick("slickPrev");
    }
  }
});

var controller = new ScrollMagic.Controller();

var stagger4 = TweenMax.staggerFrom(
  ".whyarewe-title, .whyarewe__button",
  1.5,
  {
    y: 40,
    autoAlpha: 0,
    delay: 0,
    ease: Power2.easeOut
  },
  0.6
);
var scene4 = new ScrollMagic.Scene({
  triggerElement: ".whyarewe",
  triggerHook: 0.5
})
  .setTween(stagger4)
  .addTo(controller);

var stagger5 = TweenMax.from(".whyarewe-popup", 1, {
  y: 40,
  autoAlpha: 0,
  delay: 1,
  ease: Power2.easeOut
});

var scene5 = new ScrollMagic.Scene({
  triggerElement: ".whyarewe",
  triggerHook: 0.5
})
  .setTween(stagger5)
  .addTo(controller);
//.addIndicators();

var stagger6 = TweenMax.staggerFrom(
  ".whyarewe-slide",
  1.5,
  {
    y: 40,
    autoAlpha: 0,
    delay: 0,
    ease: Power2.easeOut
  },
  0.3
);

var scene6 = new ScrollMagic.Scene({
  triggerElement: ".whyarewe",
  triggerHook: 0.5
})
  .setTween(stagger6)
  .addTo(controller);
//.addIndicators();
