var controller = new ScrollMagic.Controller();

var stagger4 = TweenMax.staggerFrom(
  ".mainland-text-signature, .mainland-text-tile, .text",
  1,
  {
    y: 40,
    autoAlpha: 0,
    delay: 0,
    ease: Power2.easeOut
  },
  0.6
);
var scene4 = new ScrollMagic.Scene({
  triggerElement: ".map",
  triggerHook: 0.2
})
  .setTween(stagger4)
  // .addIndicators({
  //   name: "fade scene2",
  //   colorTrigger: "black",
  //   colorStart: "#75C695",
  //   colorEnd: "pink"
  // })
  .addTo(controller);
