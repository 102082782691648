var controller = new ScrollMagic.Controller();

var stagger3 = TweenMax.staggerFrom(
  ".philosophy-text, .philosophy-right-bottom",
  1.5,
  {
    y: 40,
    autoAlpha: 0,
    delay: 0,
    ease: Power2.easeOut
  },
  0.6
);

var scene = new ScrollMagic.Scene({
  triggerElement: ".philosophy",
  triggerHook: 0.5
})
  .setTween(stagger3)
  .addTo(controller);
//.addIndicators();
