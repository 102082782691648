let tab = function() {
  let tabNav = document.querySelectorAll(".tabs-nav__item-details"),
    tabContent = document.querySelectorAll(".tab-details"),
    tabName;

  tabNav.forEach(item => {
    item.addEventListener("click", selectTabNav);
  });

  function selectTabNav() {
    tabNav.forEach(item => {
      item.classList.remove("active-tab");
    });
    this.classList.add("active-tab");
    tabName = this.getAttribute("data-tab-name");
    selectTabContent(tabName);
  }

  function selectTabContent(tabName) {
    tabContent.forEach(item => {
      item.classList.contains(tabName)
        ? item.classList.add("active-tab")
        : item.classList.remove("active-tab");
    });
  }
};

tab();
