import "slick-carousel";
import { TweenMax, Expo, TimelineMax } from "gsap";

TweenMax.from(".instagram-icon", 2, {
  delay: 3.4,
  opacity: 0,
  y: 30,
  ease: Expo.easeInOut
});
TweenMax.from(".progressBar", 2, {
  delay: 3.9,
  opacity: 0,
  y: 30,
  ease: Expo.easeInOut
});
TweenMax.from(".nav-wrap", 1.6, {
  delay: 3,
  opacity: 0,
  y: -30,
  ease: Expo.easeInOut
});
TweenMax.from(".phone", 1.6, {
  delay: 3.2,
  opacity: 0,
  y: -30,
  ease: Expo.easeInOut
});

TweenMax.from(".introduction__content-title", 2.5, {
  delay: 3.2,
  opacity: 0,
  y: 30,
  ease: Expo.easeInOut
});
TweenMax.from(".introduction__content-description", 2.5, {
  delay: 3.4,
  opacity: 0,
  y: 30,
  ease: Expo.easeInOut
});

$(".slider").slick({
  infinite: true,
  arrows: false,
  dots: false,
  autoplay: false,
  lazyLoad: "progressive",
  rows: 0,
  speed: 3500,
  slidesToShow: 1,
  slidesToScroll: 1
});

//ticking machine
var percenttime;
var tick;
var time = 1;
var progressBarIndex = 0;

var progress = $(".inProgress");

$(".progressBarContainer .progressBar").each(function(index) {
  var progress = "<div class='inProgress inProgress" + index + "'></div>";
  $(this).html(progress);
});

function startProgressbar() {
  resetProgressbar();
  percenttime = 0;
  tick = setInterval(interval, 10);
}

function interval() {
  if (
    $(
      '.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]'
    ).attr("aria-hidden") === "true"
  ) {
    progressBarIndex = $('.slider .slick-track div[aria-hidden="false"]').data(
      "slickIndex"
    );

    console.log(progressBarIndex);

    if (progressBarIndex == 1) {
      $(".inProgress0").addClass("on");
      $(".inProgress1").removeClass("on");
    } else if (progressBarIndex == 2) {
      $(".inProgress0").addClass("on");
      $(".inProgress1").addClass("on");
    } else if (progressBarIndex == 0) {
      $(".inProgress0").removeClass("on");
      $(".inProgress1").removeClass("on");
    }
    startProgressbar();
  } else {
    percenttime += 1 / (time + 2);
    $(".inProgress" + progressBarIndex).css({
      width: percenttime + "%"
    });
    if (percenttime >= 100) {
      $(".single-item").slick("slickNext");

      //console.log(progressBarIndex);

      if (progressBarIndex == 0) {
        $(".inProgress0").addClass("on");
      } else if (progressBarIndex == 1) {
        $(".inProgress0").addClass("on");
        $(".inProgress1").addClass("on");
      } else if (progressBarIndex == 2) {
        $(".inProgress0").removeClass("on");
        $(".inProgress1").removeClass("on");
      }

      progressBarIndex++;

      if (progressBarIndex > 2) {
        progressBarIndex = 0;
      }
      startProgressbar();
    }
  }
}

function resetProgressbar() {
  $(".inProgress").css({
    width: 0 + "%"
  });
  clearInterval(tick);
}
startProgressbar();
// End ticking machine

$(".progressBarContainer div").click(function() {
  clearInterval(tick);
  var goToThisIndex = $(this)
    .find("span")
    .data("slickIndex");
  $(".single-item").slick("slickGoTo", goToThisIndex, false);
  startProgressbar();
});

var t1 = new TimelineMax({ paused: false });

t1.to(".one", 0.8, {
  y: 6,
  rotation: 45,
  ease: Expo.easeInOut
});
t1.to(".two", 0.8, {
  y: -6,
  rotation: -45,
  ease: Expo.easeInOut,
  delay: -0.8
});

t1.to(".menu-nav", 1, {
  top: "0%",
  ease: Expo.easeOut,
  delay: 0
});

t1.staggerFrom(
  ".menu-nav ul li",
  0.8,
  { y: 100, opacity: 0, ease: Expo.easeOut },
  0.3
);

t1.reverse();
$(document).on("click", ".toggle-btn", function() {
  t1.reversed(!t1.reversed());
});
$(document).on("click", ".menu-item", function() {
  t1.reversed(!t1.reversed());
});
$(window).scroll(function() {
  if ($(window).scrollTop() >= 200) {
    $(".navigation").addClass("fixed");
  } else {
    $(".navigation").removeClass("fixed");
  }
});
