import "slick-carousel";
if (window.matchMedia("(max-width: 768px)").matches) {
  /* the viewport is less than 768 pixels wide */
  $(".slider-instagram").slick({
    infinite: true,
    arrows: false,
    dots: true,
    autoplay: false,
    rows: 0,
    slidesToShow: 1,
    slidesToScroll: 1
  });
}
