import "slick-carousel";

var controller = new ScrollMagic.Controller();
var stagger = TweenMax.staggerFrom(
  ".experience__title, .experience__description",
  1.5,
  {
    y: 40,
    autoAlpha: 0,
    delay: 0,
    ease: Power2.easeOut
  },
  0.6
);

var scene = new ScrollMagic.Scene({
  triggerElement: ".experience",
  triggerHook: 0.5
})
  .setTween(stagger)
  .addTo(controller);
//.addIndicators();

var stagger2 = TweenMax.staggerFrom(
  ".stagger-tween-item",
  1,
  {
    y: 40,
    autoAlpha: 0,
    delay: 1.5,
    ease: Power2.easeOut
  },
  0.2
);

var scene = new ScrollMagic.Scene({
  triggerElement: ".experience",
  triggerHook: 0.5
})
  .setTween(stagger2)
  .addTo(controller);
//.addIndicators();

$(".expirience-slider").slick({
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  accessibility: true,
  rows: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true
      }
    }
  ]
});

var $carousel = $(".expirience-slider");
$(document).on("keydown", function(e) {
  if (e.keyCode == 37) {
    $carousel.slick("slickPrev");
  }
  if (e.keyCode == 39) {
    $carousel.slick("slickNext");
  }
});
